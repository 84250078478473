import * as Sentry from "@sentry/react";

const production = process.env.NODE_ENV === "production";

const client = new Sentry.BrowserClient({
    transport: Sentry.makeFetchTransport,
    stackParser: Sentry.defaultStackParser,
    tracePropagationTargets: [/https:\/\/yetz\.app\/r\/(.*)/],
    integrations: Sentry.getDefaultIntegrations({}),
});

const scope = new Sentry.Scope();
scope.setClient(client);

Sentry.init({
    dsn: "https://6ae14bab3e382c222152b6c3a797d53d@o1243552.ingest.us.sentry.io/4507775874236416",
    environment: process.env.NODE_ENV,
    integrations: [
        Sentry.replayIntegration({
            networkDetailAllowUrls: [/https:\/\/yetz\.app\/r\/(.*)/],
        }),
        {
            name: "yetz-estoque",
        },
    ],
    tracesSampleRate: production ? 0.1 : 1.0,
    replaysSessionSampleRate: production ? 0.1 : 0.0,
    replaysOnErrorSampleRate: production ? 1.0 : 0.0,
});
