import "./config";
import ReactDOM from "react-dom";
import { App } from "./App";
import Providers from "./Providers";

ReactDOM.render(
    <Providers>
        <App />
    </Providers>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
