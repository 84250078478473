import { CodeInput } from "./Code";
import ImgAuth from "../../assets/sistema/auth.svg";
import { Container } from "./styles";

import { useEffect, useState } from "react";
import { Loader } from "../Loader";
import { useAuthentication } from "../../Hook/useAuthentication";

export function Authenticator() {
    const [base64, setBase64] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const { user, generateQRCode, confirmeAuthCode } = useAuthentication();
    const [hasError, setHasError] = useState<boolean>(false);
    const [code, setCode] = useState("");
    const text = base64 ? "Habilitar 2FA" : "Validar Acesso";

    async function getConfirmeAuthCode(code: string) {
        setLoading(true);
        const response = (await confirmeAuthCode(code)) as any;

        if (!response.success) {
            setHasError(true);
        }
        setLoading(false);
    }

    useEffect(() => {
        async function getAuthToken() {
            const response = await generateQRCode();
            setBase64(response);
        }
        if (user?.user?.google_2fa_secret_enabled === 0) {
            getAuthToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.user.google_2fa_secret_enabled]);

    return (
        <Container>
            <div className="container">
                <div className="container-img">
                    <img src={ImgAuth} alt="Authenticator" />
                </div>

                <div className="container-title">
                    <h3>
                        Habilitar Autenticação <br />
                        de Dois Fatores
                    </h3>

                    <p>
                        Escaneie o QR Code abaixo usando o <br />
                        <strong>App Google Authenticator</strong> no seu
                        telefone.
                    </p>

                    {base64 ? (
                        <div className="container-qrcode">
                            <img
                                src={`data:image/png;base64,${base64}`}
                                alt=""
                            />
                        </div>
                    ) : (
                        <></>
                    )}

                    <h4>Código de Verificação</h4>

                    <CodeInput
                        code={code}
                        setCode={setCode}
                        hasError={hasError}
                        setHasError={setHasError}
                    />

                    <button
                        className="contato"
                        onClick={async () => await getConfirmeAuthCode(code)}
                        disabled={code.length < 6 || loading}
                    >
                        {loading ? <Loader height={30} width={30} /> : text}
                    </button>
                </div>
            </div>
        </Container>
    );
}
