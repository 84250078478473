import { memo } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { Menu } from "../Menu";
import { getToken } from "../../util/helper";
import { setToken } from "../../services/";

import { routesProps } from "../../routes/types";
import { Footer } from "../Footer";
import { Box } from "aplication-yetz";

function RouteWrapper(props: routesProps) {
    const history = useHistory();
    const { isPrivate = false, path } = props;
    const removeMenu = [
        "/",
        "/sistema",
        "/vale-presente/:token",
        "/token/:token",
    ];
    const publicRoutes = [
        "/r/:token",
        "/c/:token",
        "/d/:token",
        "/s/:token",
        "/g/:token",
        "/m/:token",
        "/n/:token",
        "/passo-a-passo/:id",
        "/passo-a-passo/promo/:id",
        "/passo-a-passo",
    ];

    ////SANDBOX

    if (path === "/n/:token") {
        console.log("path", path);
        const hash_code = history.location.pathname.replace("/n/", "");
        return <Redirect to={"/s/" + hash_code} from={path} />;
    }
    if (path === "/d/:token") {
        console.log("path", path);
        const hash_code = history.location.pathname.replace("/d/", "");
        return <Redirect to={"/s/" + hash_code} from={path} />;
    }

    ////PRODUÇÃO

    if (path === "/m/:token") {
        console.log("path", path);
        const hash_code = history.location.pathname.replace("/m/", "");
        return <Redirect to={"/r/" + hash_code} from={path} />;
    }
    if (path === "/g/:token") {
        console.log("path", path);
        const hash_code = history.location.pathname.replace("/g/", "");
        return <Redirect to={"/r/" + hash_code} from={path} />;
    }
    if (path === "/c/:token") {
        console.log("path", path);
        const hash_code = history.location.pathname.replace("/c/", "");
        return <Redirect to={"/r/" + hash_code} from={path} />;
    }

    const token = getToken();

    if (token) {
        setToken();
    }

    if (publicRoutes.includes(path)) {
        return <Route {...props} />;
    }

    if (isPrivate && !token) {
        return <Redirect to="/" from={path} />;
    }

    if (path === "*") {
        return <Redirect to="/sistema" from={path} />;
    }

    if (path === "/" && token) {
        return <Redirect to="/sistema" from={path} />;
    }

    return (
        <>
            {!removeMenu.includes(path) ? (
                <>
                    <Menu />
                    <div className="content">
                        <Route {...props} />
                        <Box mt="auto">
                            <Box mt="64px">
                                <Footer />
                            </Box>
                        </Box>
                    </div>
                </>
            ) : (
                <Route {...props} />
            )}
        </>
    );
}

export default memo(RouteWrapper);
