import { useState, useEffect } from "react";
import { Modal } from "../../components/Modal";
import { ModalVideo } from "../../components/PassoAPasso/video";
import { ValePresente } from "../../components/ValePresente";

import { Error } from "../../components/ValePresente/error";
import { Encerrada } from "../../components/ValePresente/encerrada";
import { ExpiradoPersonalite } from "../../components/ValePresente/expiradoPersonnalite";
import { Expirado } from "../../components/ValePresente/expirado";
import { Footer } from "../../components/Footer";
import { Bloqueado } from "../../components/ValePresente/bloqueado";

import { ValePresenteCineMarckCombo } from "../../components/ValePresenteCineMarckCombo";
import { MultiplosVoucher } from "../../components/MultiplosVoucher";
import { MultiplosVoucherGrandCru } from "../../components/MultiplosVoucher/grandCru";
import { PersonaliteCodigo } from "../../components/MultiplosVoucher/personaliteCodigo";
import { PersonaliteCodigoSemValor } from "../../components/MultiplosVoucher/personaliteCodigoSemValor";
import { UniclassSemValor } from "../../components/MultiplosVoucher/uniclassSemValor";
import { UniclassCodigo } from "../../components/MultiplosVoucher/uniclassCodigo";
import { Loader } from "../../components/Loader";

import { ModalTutorial } from "../../components/PassoAPasso/modal";
import { useHistory } from "react-router-dom";

import { formatDateUser } from "../../util/helper";
import { useVoucherConfig } from "../../Hook/useVoucherConfig";
import axios from "axios";

interface Item {
    date: string | null;
    code: string | null;
    password: string | null;
    descricao: string | null;
    expires_at: string | null | any;
    foto_capa: string | null;
    nome: string | null;
    selo: string | null;
    tutorial_observacao: string | null;
    tutorial_passos: string | null;
    tutorial_url: string | null;
    validade: string | null;
    valor: string | null;
    slug: string | null;
    template?: string | null;
    informacoes_importantes: string;
    detalhes: string;
    codes?: any;
    template_voucher_id: number | null;
    variacao?: any;
}
export interface ResponseVoucher {
    success: boolean;
    error: string;
    produto_id?: number;
    status: string;
    voucher?: Item[];
    codes?: any;
    expires_at?: string | null | any;
    template_voucher_id?: any;
}

export function PageSandBox() {
    const history = useHistory();
    const { setHashCode } = useVoucherConfig();
    const [voucher, setVoucher] = useState<ResponseVoucher>(
        {} as ResponseVoucher
    );
    const [codes, setCodes] = useState<string[][]>([]);
    // const [templateId, setTemplateId] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [modalVideo, setModalVideo] = useState<boolean>(false);
    const [modalTutorial, setModalTutorial] = useState<boolean>(false);
    const personalite = [2, 4, 5, 6, 8];
    const hash_code = history.location.pathname.replace("/s/", "");
    const templaId = voucher.template_voucher_id
        ? voucher.template_voucher_id
        : 0;
    const configProduto = {
        title: "Produtos",
        video: voucher?.voucher ? voucher?.voucher[0].tutorial_url : "",
        tutorial: voucher?.voucher ? voucher?.voucher[0].tutorial_passos : "[]",
        img: voucher?.voucher ? voucher?.voucher[0].foto_capa : "",
        tutorialObservacao: voucher?.voucher
            ? voucher?.voucher[0].tutorial_observacao
            : "",
        onClose: () => {
            setModalTutorial(false);
            setModalVideo(false);
        },
        openModalVideo: () => {
            setModalTutorial(false);
            setModalVideo(true);
        },
        dados: voucher?.voucher?.length ? voucher?.voucher[0] : {},
    };

    const configVideo = {
        video: voucher?.voucher ? voucher?.voucher[0].tutorial_url : "",
        modalVideo: modalVideo,
        closeModal: () => {
            setModalTutorial(true);
            setModalVideo(false);
        },
        // video: "81db6ab30a",
    };

    function clickOutModal(e: React.MouseEvent<HTMLElement>) {
        const element = (e.target as HTMLElement).dataset;

        if (element?.closemodal) {
            setModalTutorial(false);
            setModalVideo(false);
        }
    }

    function renderTemplates() {
        if (loading) {
            return (
                <div className="loader">
                    <Loader />
                </div>
            );
        }
        if (voucher?.voucher) {
            // setTemplateId(voucher?.voucher[0].template_voucher_id || 0);
            switch (voucher?.voucher[0].template_voucher_id) {
                case 1:
                    return (
                        <ValePresente
                            voucher={
                                voucher.voucher ? voucher.voucher[0] : undefined
                            }
                            loading={loading}
                            setModalTutorial={setModalTutorial}
                        />
                    );
                case 2:
                    return (
                        <MultiplosVoucher
                            setModalTutorial={setModalTutorial}
                            voucher={voucher}
                            loading={loading}
                        />
                    );
                case 3:
                    return (
                        <ValePresenteCineMarckCombo
                            voucher={
                                voucher.voucher ? voucher.voucher[0] : undefined
                            }
                            codes={codes}
                            loading={loading}
                            setModalTutorial={setModalTutorial}
                            sandbox={true}
                        />
                    );
                case 4:
                    return (
                        <MultiplosVoucherGrandCru
                            setModalTutorial={setModalTutorial}
                            voucher={voucher}
                            loading={loading}
                        />
                    );
                case 5:
                    return (
                        <PersonaliteCodigo
                            setModalTutorial={setModalTutorial}
                            voucher={voucher}
                            loading={loading}
                            name={voucher?.voucher[0].nome || ""}
                        />
                    );
                case 6:
                    return (
                        <UniclassCodigo
                            setModalTutorial={setModalTutorial}
                            voucher={voucher}
                            loading={loading}
                            name={voucher?.voucher[0].nome || ""}
                        />
                    );
                case 7:
                    return (
                        <PersonaliteCodigoSemValor
                            setModalTutorial={setModalTutorial}
                            voucher={voucher}
                            loading={loading}
                            name={voucher?.voucher[0].nome || ""}
                        />
                    );
                case 8:
                    return (
                        <UniclassSemValor
                            setModalTutorial={setModalTutorial}
                            voucher={voucher}
                            loading={loading}
                            name={voucher?.voucher[0].nome || ""}
                        />
                    );
                default:
                    return <></>;
            }
        }
    }

    useEffect(() => {
        async function checkToken() {
            setLoading(true);
            //xvWLzTjtEtQzI0C.3482
            try {
                const { data } = await axios.post<ResponseVoucher>(
                    `https://yetzestoquesandbox.yetzcards.com.br/api/pedido/check-url`,
                    {
                        hash_code,
                    }
                );

                setCodes(data.codes);
                setHashCode(hash_code);
                if (
                    data.voucher ? data.voucher[0].template === "LINK" : false
                ) {
                    if (data.codes) {
                        window.open(data.codes[0], "_self");
                        // history.push("/");
                        return;
                    }
                }

                const regexHasUrl =
                    /(http|https):\/\/(www\.)?[a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;

                if (regexHasUrl.test(data.codes[0] || "")) {
                    window.location.href = data.codes[0] || "";
                } else {
                    setVoucher(data);
                }
            } catch (error: any) {
                setVoucher(error.response.data);
            } finally {
                setLoading(false);
            }
        }

        checkToken();
    }, [hash_code, history, setHashCode]);

    console.log(
        (voucher?.status === "EXPIRADO" ||
            voucher?.error === "Link expirado") &&
            personalite.includes(voucher?.template_voucher_id as number)
    );

    return (
        <>
            {voucher.success === false ? (
                <>
                    {voucher?.status === "BLOQUEADO" && <Bloqueado />}
                    {voucher?.status === "ESTORNADO" && <Encerrada />}
                    {voucher?.error === "Link not found" && <Error />}
                    {(voucher?.status === "EXPIRADO" ||
                        voucher?.error === "Link expirado") &&
                    personalite.includes(
                        voucher?.template_voucher_id as number
                    ) ? (
                        <ExpiradoPersonalite
                            templateId={templaId}
                            date={formatDateUser(voucher?.expires_at)}
                        />
                    ) : voucher?.status === "EXPIRADO" ||
                      voucher?.error === "Link expirado" ? (
                        <Expirado date={formatDateUser(voucher?.expires_at)} />
                    ) : null}

                    <Footer />
                </>
            ) : (
                renderTemplates()
            )}

            <Modal
                component={ModalTutorial}
                active={modalTutorial}
                rest={configProduto}
                onClick={clickOutModal}
                maxWidthMobile={true}
            />
            <Modal
                onClick={clickOutModal}
                component={ModalVideo}
                active={modalVideo}
                rest={configVideo}
            />
        </>
    );
}
